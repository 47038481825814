.root {
  padding: 4rem 0;
}

.media {
  height: 250px;
}

.capabilityCard {
  transition: transform 0.3s;
}

.capabilityCard:hover {
  transform: scale(1.05);
}

.imageBox {
  display: flex;
  justify-content: center; /* Centers image horizontally */
  align-items: center; /* Centers image vertically */
  height: '100%'; /* Remove fixed height if previously set */
  overflow: hidden;
}

.contentImage {
  max-width: 100%; /* Image will scale up to the size of its container */
  height: auto; /* Maintain aspect ratio */
}

.contentText {
  display: flex;
  flex-direction: column;
}

.flex-container {
  display: flex;
  align-items: center; /* This will vertically align them in the center */
  justify-content: start; /* This aligns items to the start of the flex-direction, which is row by default */
}

.right-text {
  /* Your existing styles for the text that is to the right of heading1 */
  margin-left: 20px; /* Adjust as needed for spacing */
}

.fixedCard {
  height: 200px;
}

.leftAlignedText {
  text-align: left;
}

.contentTextWithImage {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start; 
  justify-content: center;
  text-align: left;
}

.textList {
  display: flex;
  flex-direction: column;
  padding: 0; /* Removes default padding of ul */
  list-style-type: none; /* Removes bullet points, optional */
}


.heading {
  margin-right: 50%;
  margin-bottom: 30px;
  font-weight: bold;
}

.titleheading {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-weight: bold;
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 80%;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}