drawer {
    width: 260px;
    flex-shrink: 0;
    box-shadow: 2px 0 5px rgba(0,0,0,0.05);
  }
  
  .drawerPaper {
    width: 260px;
    background-color: #004752;
    color: #FFF;
  }
  
  .listItem {
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  
  .listItem:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .listItem:hover .listItemIcon {
    color: #87C45E;
  }
  
  .activeItem {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .activeItem .listItemIcon {
    color: #6DAE43;
  }
  
  .listItemIcon {
    color: rgba(255,255,255,0.7);
    min-width: 40px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    margin-bottom: 16px;
  }
  
  .logo {
    height: auto;
    width: 10rem;
    display: flex;
    align-self: center;
  }
  
  .brand {
    font-size: 1.5rem;
    color: #87C45E;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  .sidebar {
    width: 260px;
    flex-shrink: 0;
    box-shadow: 2px 0 5px rgba(0,0,0,0.05);
    background-color: #004752; /* Always '#004752' */
  }
  
  .sidebarPaper {
    width: 260px;
    background-color: #004752; /* Always '#004752' */
    color: #FFF;
  }